import React, { useState, useEffect } from "react"
import styles from "./event.module.css"
import { css } from "@emotion/core"
import { Box, Collapse } from "@chakra-ui/core"
import { useTranslation } from "gatsby-plugin-react-i18next"

import ReactMarkdown from "react-markdown"
import RemarkBreaks from "remark-breaks"

const Forschung = ({
  author,
  title,
  subtitle,
  text,
  gallery,
  lightbox,
  setData,
  viewLightbox,
  openDefault = false,
}) => {
  const [show, setShow] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    openDefault && setShow(true)
  }, [openDefault])

  const passData = () => {
    setData({ title, text, gallery })
    viewLightbox(true)
  }

  return (
    <Box pos="relative" mr={["3em", "3em", "5em"]}>
      <Box
        cursor={show ? "url(/close.svg), auto" : "pointer"}
        onClick={() =>
          lightbox
            ? passData()
            : setShow(lightbox && show === false ? true : !show)
        }
        css={css`
          .more-info {
            visibility: hidden;
          }
          @media (hover) {
            &:hover .more-info {
              visibility: ${show ? "hidden" : "visible"};
            }
          }
        `}
      >
        <Box
          className="more-info"
          bg="green"
          pos="absolute"
          top="0"
          left="0"
          zIndex="3"
          h="100%"
          w="100%"
          color="white"
          fontSize={["5xl", "5xl", "3xl"]}
          px={["0.4em", "0.4em", "0.4em"]}
          py={["0.3em", "0.3em", "0.3em"]}
          fontFamily="Traulha Italica, sans-serif"
        >
          {t("Weitere Informationen")}
        </Box>
        {author && (
          <Box
            color="green"
            fontSize={["5xl", "5xl", "5xl"]}
            lineHeight={["1.25", "1.2", "1.15em"]}
          >
            {author}
          </Box>
        )}
        {title && (
          <Box
            color="red"
            py={["0", "0", "0em"]}
            lineHeight={["1.25", "1.2", "1.15em"]}
            fontSize={["5xl", "5xl", "5xl"]}
          >
            {title}
          </Box>
        )}
        {subtitle && (
          <Box
            color="black"
            pt={["0.2em", "0.2em", "0.1em"]}
            lineHeight={["1.25", "1.2", "1.15em"]}
            fontSize={["4-5xl", "3xl", "xl"]}
          >
            {subtitle}
          </Box>
        )}
        {text && !lightbox && (
          <Collapse
            color="black"
            isOpen={show}
            fontSize={["4-5xl", "3xl", "xl"]}
            pt={["1.4em", "1.4em", "1.4em"]}
            fontFamily="Overpass"
          >
            <ReactMarkdown
              source={text}
              className={styles.event}
              plugins={[RemarkBreaks]}
            />
          </Collapse>
        )}
      </Box>
    </Box>
  )
}

export default Forschung
