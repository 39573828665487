import React, { useState } from "react"
import { graphql } from "gatsby"

import { Box } from "@chakra-ui/core"

import Masonry from "containers/masonry"
import Layout from "containers/layout"
import SEO from "components/seo"
import ForschungsItem from "containers/forschung"
// import ZurueckLink from "components/zurueckLink"
import Lightbox from "containers/lightbox"
import { onlyClient } from "./index"

const Forschung = ({ location, data }) => {
  const [viewLightbox, setViewLightbox] = useState(false)
  const [lightboxData, setLightboxData] = useState({})

  return (
    <Layout path={location.pathname} bgHeader="greenLight">
      <SEO
        title={data.strapi?.forschung?.meta?.title}
        description={data.strapi?.forschung?.meta?.description}
      />
      <Box
        pt={["16em", "15.5em", "13em"]}
        pr={["4em", "10em", "5em"]}
        bg="greenLight"
        w="100%"
        minHeight={["100vh", "100vh", "calc(100vh - 5.6em)"]}
      >
        {onlyClient && (
          <Masonry
            breakpointColumns={{ 2: "(min-width: 75em)" }}
            gap={["5em", "5em", "4em"]}
            defaultColumn={1}
          >
            {data.strapi?.forschung?.inhalt &&
              data.strapi?.forschung?.inhalt.map((item, index) => {
                if (!item.exploration) {
                  return null
                }

                const images = item.exploration && item.exploration.gallery //&&
                {
                  /* item.exploration.gallery.map(
                    src =>
                      src.imageFile &&
                      src.imageFile.childImageSharp &&
                      src.imageFile.childImageSharp.fluid
                  ) */
                }

                return (
                  <ForschungsItem
                    key={index}
                    author={item.exploration.author}
                    title={item.exploration.title}
                    subtitle={item.exploration.subtitle}
                    text={item.exploration.text}
                    lightbox={item.exploration.lightbox}
                    openDefault={item.exploration.openDefault}
                    gallery={images}
                    setData={setLightboxData}
                    viewLightbox={setViewLightbox}
                  />
                )
              })}
          </Masonry>
        )}
      </Box>
      <Lightbox
        text={lightboxData.text}
        title={lightboxData.title}
        images={lightboxData.gallery}
        visibleFunc={setViewLightbox}
        visible={viewLightbox}
        animate={true}
        viewImageInfo={viewLightbox}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    strapi {
      forschung {
        meta {
          title
          description
        }
        inhalt {
          ... on STRAPI_ComponentPageResearch {
            exploration {
              id
              author
              title
              subtitle
              text
              text_en
              lightbox
              openDefault
              gallery {
                url
                imageFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
        }
        inhalt_en {
          ... on STRAPI_ComponentPageResearch {
            exploration {
              id
              author
              title
              subtitle
              text
              text_en
              lightbox
              openDefault
              gallery {
                url
                imageFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Forschung
